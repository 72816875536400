import './logo.scss'

/**
 * Static markup component to abstract logo SVG markup from the main view component
 *
 * @returns {JSX}
 */
const Logo = () => (
  <div className="logo__wrapper">
    <svg id="logoSvg" viewBox="0 0 280 69.79" xmlns="http://www.w3.org/2000/svg">
      <g className="logo" fill="#111">
        <g className="logo__slash">
          <path d="M167.89 0l-22.53 68.15h-7.69L160.2 0z" />
        </g>
        <g className="logo__left">
          <path d="M28.58 42H8c0 .82-.27 1.65-.27 2.47 0 3.3 1.92 5.77 5.77 5.77 3.3 0 5.77-1.37 8.24-3.57l4.4 5.5a19.4 19.4 0 01-13.46 4.95c-6 0-12.64-3-12.64-12.09C0 33.52 5.77 22 17.59 22c7.41 0 12.09 4.93 12.09 12.35a43.11 43.11 0 01-1.1 7.65zM17 28.58c-3.57 0-6.32 2.2-8 8h12.71a6.83 6.83 0 00.27-2.2c-.27-3.6-2.2-5.8-4.98-5.8zM41.49 56.6h-8.24l6.87-34.35h8.24zm8-39.84h-8.27l1.37-6.87h8.52zM76.39 55.51c-1.92 9.89-8.52 14.28-16.49 14.28-4.95 0-8.52-1.37-11.27-4.67l5.77-4.95a7.19 7.19 0 005.77 2.75c4.67 0 7.42-3 8.24-7.42l.82-3.57a9 9 0 01-7.69 3.57c-4.95 0-9.62-3.3-9.62-9.62a38 38 0 01.84-6.88c1.1-6.59 2.47-10.44 5.22-13.19a12.18 12.18 0 018.79-3.3c3.57 0 6.32 1.37 7.69 3.85l.54-3.55h8zM68.15 29.4c-4.12 0-6 3.3-7.14 9.34-.27 2.2-.55 3.57-.55 4.67 0 2.47 1.1 4.67 4.12 4.67 4.12 0 6-3.3 7.14-9.34.27-2.2.55-3.57.55-4.67 0-3.02-1.38-4.67-4.12-4.67zM116.78 35.17l-4.4 21.43h-8.24l4.12-20.88c0-.82.27-1.65.27-2.2 0-3-1.92-4.4-4.12-4.4s-6 1.1-7.14 6.59L93.15 56.6h-8.24l9.34-47h8.24L99.47 25a12.38 12.38 0 018.53-3c4.12 0 9.07 2.75 9.07 9.62-.01 1.38-.29 2.45-.29 3.55zM132.72 49.46a1.66 1.66 0 01-.82-1.65 3 3 0 01.27-1.37l3.3-16.76h3.85l2.2-6.32h-4.95l1.92-10.17h-8.24l-1.92 10.17h-3.57l-1.37 6.32h3.57l-3.3 16.76c0 .82-.27 1.65-.27 2.75 0 4.12 2.75 6.87 6.87 7.42z" />
        </g>
        <g className="logo__right">
          <path d="M204.16 56.6h-8.24l6.87-34.35H211zm8-39.84h-8.24l1.37-6.87h8.24zM244.83 35.17l-4.4 21.43h-8.24l4.12-20.88c0-.82.27-1.65.27-2.2 0-3-1.92-4.4-4.12-4.4s-6 1.1-7.14 6.59L221.2 56.6H213l6.87-34.35h8l-.55 3.3a12.88 12.88 0 018.99-3.55c4.12 0 8.79 2.75 8.79 9.62 0 1.38-.27 2.45-.27 3.55zM278.9 42h-20.61c0 .82-.27 1.65-.27 2.47 0 3.3 1.92 5.77 5.77 5.77 3.3 0 5.77-1.37 8.24-3.57l4.4 5.5A19.4 19.4 0 01263 57.15c-6 0-12.64-3-12.64-12.09 0-11.54 5.77-23.08 17.59-23.08C275.33 22 280 26.93 280 34.35a27.3 27.3 0 01-1.1 7.65zm-11.54-13.42c-3.57 0-6.32 2.2-8 8H272a6.83 6.83 0 00.27-2.2c.04-3.6-1.89-5.8-4.91-5.8zM183.28 22a12.88 12.88 0 00-9.07 3.57l.55-3.3h-3.57l-11.27 33.51-.27.82h8.24L172 35.72c1.1-5.5 4.67-6.59 7.14-6.59a4.11 4.11 0 014.12 4.4 6.05 6.05 0 01-.27 2.2l-4.11 20.87h8.24l4.4-21.43a16.22 16.22 0 00.55-3.85c0-6.59-4.95-9.32-8.79-9.32z" />
        </g>
      </g>
    </svg>
  </div>
)

export default Logo
